import {getUtf8StringLength} from '@github-ui/text'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onInput} from '@github-ui/onfocus'
import {remoteForm} from '@github/remote-form'
import {requestSubmit} from '@github-ui/form-utils'
import type {SortableEvent} from '@github/sortablejs'

function hideListingError(element: Element) {
  const container = element.closest<HTMLElement>('.js-marketplace-listing-form-container')!
  const errorContainer = container.querySelector<HTMLElement>('.js-marketplace-listing-error-container')!
  errorContainer.hidden = true
}

function showListingError(form: Element, message: string) {
  const container = form.closest<HTMLElement>('.js-marketplace-listing-form-container')!

  const errorContainer = container.querySelector<HTMLElement>('.js-marketplace-listing-error-container')!
  errorContainer.hidden = false

  const error = errorContainer.querySelector<HTMLElement>('.js-marketplace-listing-error')!
  error.textContent = message
}

function flashSaveNotice(form: Element) {
  const noticeContainer = form.querySelector('.js-marketplace-listing-save-notice')

  if (noticeContainer) {
    noticeContainer.classList.add('visible')

    setTimeout(() => noticeContainer.classList.remove('visible'), 1500)
  }
}

function flashSaveError(form: Element) {
  const errorContainer = form.querySelector('.js-marketplace-listing-save-error')

  if (errorContainer) {
    errorContainer.classList.add('visible')

    setTimeout(() => errorContainer.classList.remove('visible'), 1500)
  }
}

function updateLogoBackgroundColor(form: Element, bgcolor: string) {
  const container = form.closest<HTMLElement>('.js-marketplace-listing-form-container')!
  const bgcolorElements = container.querySelectorAll('.js-marketplace-listing-bgcolor')

  for (const el of bgcolorElements) {
    if (!(el instanceof HTMLElement)) continue
    el.style.backgroundColor = `#${bgcolor}`
  }
}

function updateHeroTextColor(isLightText: string) {
  const heroCardNameEl = document.querySelector('.js-hero-listing-name')

  if (heroCardNameEl) {
    if (isLightText === 'true') {
      heroCardNameEl.classList.add('color-text-white')
      heroCardNameEl.classList.remove('color-fg-default')
    } else {
      heroCardNameEl.classList.remove('color-text-white')
      heroCardNameEl.classList.add('color-fg-default')
    }
  }
}

function updateHeroCardPreviewBackgroundColor(bgcolor: string) {
  const heroCardLogoEl = document.querySelector('.js-hero-listing-container .js-hero-listing-bgcolor')

  if (heroCardLogoEl instanceof HTMLElement) {
    heroCardLogoEl.style.backgroundColor = `#${bgcolor}`
  }
}

function updateHeroCardPreviewDescription(description: string) {
  const heroCardDescriptionEl = document.querySelector('.js-hero-listing-description')

  if (heroCardDescriptionEl) {
    heroCardDescriptionEl.textContent = description
  }
}

function showRemainingCharacterCount(value: string, limit: number, field: HTMLInputElement | HTMLTextAreaElement) {
  const container = field.closest<HTMLElement>('.js-listing-characters-remaining-container')!
  const remainingEl = container.querySelector<HTMLElement>('.js-listing-characters-remaining')!
  const suffix = String(remainingEl.getAttribute('data-suffix'))
  const valueLength = getUtf8StringLength(value)
  const remainingCount = limit - valueLength

  remainingEl.textContent = `${remainingCount} ${suffix}`
  remainingEl.classList.toggle('color-fg-danger', remainingCount <= 5)
}

onInput('.js-marketplace-short-description-field', function (event) {
  const input = event.target as HTMLInputElement
  const formGroup = input.closest<HTMLElement>('.form-group')!

  if (input.validity.patternMismatch) {
    formGroup.classList.add('errored')
  } else {
    formGroup.classList.remove('errored')
  }
})

on('change', '.js-marketplace-listing-light-text', function (event) {
  const selectBox = event.target as HTMLSelectElement
  updateHeroTextColor(selectBox.value)
})

on('change', '.js-marketplace-listing-logo-background-color', function (event) {
  const input = event.target as HTMLInputElement
  const form = input.closest<HTMLFormElement>('form')!

  updateLogoBackgroundColor(form, input.value)
  updateHeroCardPreviewBackgroundColor(input.value)
})

remoteForm('.js-marketplace-listing-form', async function (form, wants) {
  hideListingError(form)
  let response
  try {
    response = await wants.json()
  } catch (error) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    if (!error.response) throw error
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    const errorResponse = error.response.json.error
    showListingError(form, errorResponse)
    return
  }

  if (response.json.path) {
    const currentPath = window.location.pathname
    const newPath = response.json.path
    if (currentPath !== newPath) {
      // Redirect user to new edit page because the listing slug changed
      window.location.href = newPath
      return
    }
  }

  if (response.json.bgcolor) {
    updateLogoBackgroundColor(form, response.json.bgcolor)
    updateHeroCardPreviewBackgroundColor(response.json.bgcolor)
  }
  if (response.json.short_description) {
    updateHeroCardPreviewDescription(response.json.short_description)
  }

  flashSaveNotice(form)
})

remoteForm('.js-marketplace-listing-screenshot-update-form', async function (form, wants) {
  try {
    await wants.text()
  } catch (error) {
    flashSaveError(form)
    return
  }

  const captionInput = form.querySelector<HTMLInputElement>('.js-marketplace-listing-screenshot-caption-field')!
  const presentCaptionContainer = form.querySelector<HTMLElement>('.js-marketplace-listing-screenshot-caption-present')!
  const emptyCaptionContainer = form.querySelector<HTMLElement>('.js-marketplace-listing-screenshot-caption-empty')!

  presentCaptionContainer.textContent = captionInput.value
  if (captionInput.value.trim().length < 1) {
    /* eslint-disable-next-line github/no-d-none */
    presentCaptionContainer.classList.add('d-none')
    /* eslint-disable-next-line github/no-d-none */
    emptyCaptionContainer.classList.remove('d-none')
  } else {
    /* eslint-disable-next-line github/no-d-none */
    presentCaptionContainer.classList.remove('d-none')
    /* eslint-disable-next-line github/no-d-none */
    emptyCaptionContainer.classList.add('d-none')
  }

  flashSaveNotice(form)
})

remoteForm('.js-marketplace-listing-screenshot-delete-form', async function (form, wants) {
  hideListingError(form)
  const button = form.querySelector<HTMLButtonElement>('.js-marketplace-listing-screenshot-delete-button')!
  button.disabled = true

  try {
    await wants.text()
  } catch (error) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    if (error.response) {
      try {
        // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
        showListingError(form, error.response.json.error)
      } catch (err) {
        throw error
      } finally {
        button.disabled = false
      }
      return
    } else {
      throw error
    }
  }

  const container = form.closest<HTMLElement>('.js-marketplace-listing-screenshot-container')!
  container.remove()
})

onInput('textarea.js-listing-characters-remaining-field', function (event) {
  const textarea = event.target as HTMLTextAreaElement
  const limit = parseInt(textarea.getAttribute('data-character-limit') || '', 10)

  showRemainingCharacterCount(textarea.value, limit, textarea)
})

onInput('input.js-listing-characters-remaining-field', function (event) {
  const input = event.target as HTMLInputElement
  const limit = input.maxLength

  showRemainingCharacterCount(input.value, limit, input)
})

on(
  'details-menu-selected',
  '.js-languages-menu',
  function (event) {
    const checkedItems = event.currentTarget.querySelectorAll('[role="menuitemcheckbox"][aria-checked="true"]')
    document.querySelector<HTMLElement>('.js-language-selection')!.textContent = Array.from(checkedItems)
      .map(e => (e.textContent || '').trim())
      .sort()
      .join(', ')
  },
  {capture: true},
)

function handleScreenshotDrag(e: SortableEvent) {
  const screenshot = e.item
  const form = screenshot.querySelector<HTMLFormElement>('.js-marketplace-listing-screenshot-resequence-form')!
  const prevScreenshotIdElement = form.elements.namedItem(
    'marketplace_listing_screenshot[previousScreenshotId]',
  ) as HTMLInputElement

  const prevScreenshot = screenshot.previousElementSibling
  if (prevScreenshot instanceof HTMLElement) {
    prevScreenshotIdElement.value = prevScreenshot.getAttribute('data-screenshot-id')!
  } else {
    prevScreenshotIdElement.value = ''
  }

  requestSubmit(form)
}
// ****************************************************************************
// Drag and drop setup

observe('.js-draggable-screenshots-container', {
  async add(el) {
    const {Sortable} = await import('../sortable-behavior')
    Sortable.create(el, {
      animation: 150,
      draggable: '.js-draggable-screenshot',
      onUpdate: handleScreenshotDrag,
    })
  },
})
